import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'srs.sharedcomponents/lib/esm/components/Spinner';
import * as S from './style';
import { env } from 'srs.sharedcomponents/lib/esm/api/env';
import {
    updateMultiSelectFilters,
    updateisMultiSelectFiltersApplied,
    updateVehicleApplicationClicked,
    setSearchParam
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface SupplierList {
    [key: string]: string[];
}

const SupplierListComponent: React.FC = () => {
    const [suppliers, setSuppliers] = useState<SupplierList>({});

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get<SupplierList>(
                    `${env.REACT_APP_SERVER_URL}/Catalog/GetSupplierList`
                );
                setSuppliers(response.data);
            } catch (error) {
                console.error('Error fetching supplier list:', error);
            }
        };

        fetchSuppliers();
    }, []);

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLetterClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        letter: string
    ) => {
        e.preventDefault();
        const element = document.getElementById(letter);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const getItemsForSelectedSupplier = (supplier: string) => {
        const obj = {
          OnSale: false,
          NewProduct: false,
          MinPrice: 0,
          MaxPrice: 0,
          Categories: [],
          Suppliers: [supplier],
          Makes: [],
          Years: [],
          Models: [],
          Components: [],
          callForPricing: false,
        };
        dispatch(updateMultiSelectFilters(obj));
        dispatch(updateisMultiSelectFiltersApplied(true));
        dispatch(updateVehicleApplicationClicked(true));
        dispatch(setSearchParam(""));
     
        history.push(`/ProductListCatalog?TAG=${encodeURIComponent(supplier)}`);
      };
    if (!suppliers || Object.keys(suppliers).length === 0) {
        return (
            <S.Container
                style={{
                    minHeight: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spinner />
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Breadcrumbs data-cy="breadcrumbs">
                <S.BreadcrumbLink href="#">Home</S.BreadcrumbLink> {'>'}{' '}
                <S.BreadcrumbSpan>Supplier List</S.BreadcrumbSpan>
            </S.Breadcrumbs>
            <S.Title>Browse Suppliers</S.Title>
            <S.Alphabet data-cy="alphabet">
                {alphabet.map((letter) => (
                    <S.AlphabetLink
                        key={letter}
                        onClick={(e) => handleLetterClick(e, letter)}
                        data-cy={`alphabet-link-${letter}`}
                        href={`#${letter}`}
                    >
                        {letter}
                    </S.AlphabetLink>
                ))}
            </S.Alphabet>
            <S.SupplierListContainer data-cy="supplier-list">
                {alphabet.map((letter) => (
                    <React.Fragment key={letter}>
                        <h2 id={letter}>{letter}</h2>
                        <S.SupplierColumn data-cy={`supplier-column-${letter}`}>
                            {suppliers[letter] ? (
                                suppliers[letter].map((supplier) => (
                                    <S.SupplierLink
                                        key={supplier}
                                        onClick={() => getItemsForSelectedSupplier(supplier)}
                                        data-cy={`supplier-link-${supplier}`}
                                    >
                                        {supplier}
                                    </S.SupplierLink>
                                ))
                            ) : (
                                <p>No suppliers</p>
                            )}
                        </S.SupplierColumn>
                    </React.Fragment>
                ))}
            </S.SupplierListContainer>
        </S.Container>
    );
};

export default SupplierListComponent;
