import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import { convertToMultiSelectComponentData } from './MultiSelectDataHandling';

export const extractFilterData = (
    updatedFilterItems: IItemListResponse | undefined,
    totalItems: IItemListResponse | undefined
) => {
    const MultiSelectData = convertToMultiSelectComponentData(
        updatedFilterItems?.refineCatalogs
    );

    const makes = MultiSelectData.vehicles
        .map((v) => v.make)
        .sort((a, b) => a.localeCompare(b));

    const yearsSet = new Set<string>();
    MultiSelectData.vehicles.forEach((vehicle) => {
        vehicle.years.forEach((yearObj) => {
            yearsSet.add(yearObj.year);
        });
    });
    const allYears = Array.from(yearsSet).sort((a, b) => b.localeCompare(a));

    const modelsSet = new Set<string>();
    MultiSelectData.vehicles.forEach((vehicle) => {
        vehicle.years.forEach((yearObj) => {
            yearObj.models.forEach((modelObj) => {
                modelsSet.add(modelObj.model);
            });
        });
    });
    const allModels = Array.from(modelsSet).sort((a, b) => a.localeCompare(b));

    const extractComponents = (modelObj: { components: any }) =>
        modelObj.components;
    const extractModels = (yearObj: { models: any[] }) =>
        yearObj.models.flatMap(extractComponents);
    const extractYears = (vehicle: { years: any[] }) =>
        vehicle.years.flatMap(extractModels);

    const getAllComponents = (vehicles: any[]): Set<string> => {
        const componentsSet = new Set<string>();
        vehicles.forEach((vehicle) => {
            extractYears(vehicle).forEach((component) => {
                componentsSet.add(component);
            });
        });
        return componentsSet;
    };

    const allComponents = Array.from(
        getAllComponents(MultiSelectData.vehicles)
    ).sort((a, b) => a.localeCompare(b));

    const allCategoriesWithChildren =
        updatedFilterItems?.childrenCatalogs
            ?.filter((category) => category?.items?.length > 0)

            ?.map((category) => ({
                label: category.label,
                items: category.items || [],
                code: category.code ?? '',
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    const allInitialCategoriesWithChildren =
        totalItems?.childrenCatalogs
            ?.filter((category) => category?.items?.length > 0)
            ?.map((category) => ({
                label: category.label,
                items: category.items || [],
                code: category.code ?? '',
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    const allSuppliers =
        Array.from(
            new Set(
                updatedFilterItems?.suppliers?.map((supplier) => supplier.label)
            )
        ).sort((a, b) => a.localeCompare(b)) ?? [];

    return {
        makes,
        allYears,
        allModels,
        allComponents,
        allCategoriesWithChildren,
        allInitialCategoriesWithChildren,
        allSuppliers,
    };
};
