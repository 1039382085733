import { RefineCatalogsResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
interface MultiSelectMultiSelectComponentData {
    vehicles: {
        make: string;
        years: {
            year: string;
            models: {
                model: string;
                components: string[];
            }[];
        }[];
    }[];
}
export function convertToMultiSelectComponentData(
    refineCatalogs: RefineCatalogsResponse[] | undefined
): MultiSelectMultiSelectComponentData {
    const vehicles = refineCatalogs?.map((make) => convertMake(make)) || [];
    return { vehicles };
}

export function getFilteredData(
    totalItems: RefineCatalogsResponse[] | undefined,
    selectedValues: {
        make: string[];
        year: string[];
        model: string[];
        component: string[];
    },
    allMakes: string[],
    allYears: string[],
    allModels: string[],
    allComponents: string[]
) {
    const allMakeValues = filterDataBySelections(totalItems, {
        make: [],
        year: selectedValues.year,
        model: selectedValues.model,
        component: selectedValues.component,
    });

    const allYearValues = filterDataBySelections(totalItems, {
        make: selectedValues.make,
        year: [],
        model: selectedValues.model,
        component: selectedValues.component,
    });

    const allModelValues = filterDataBySelections(totalItems, {
        make: selectedValues.make,
        year: selectedValues.year,
        model: [],
        component: selectedValues.component,
    });
    const allComponentValues = filterDataBySelections(totalItems, {
        make: selectedValues.make,
        year: selectedValues.year,
        model: selectedValues.model,
        component: [],
    });

    const filteredData = filterDataBySelections(totalItems, selectedValues);

    allMakes.forEach((make) => {
        if (allMakeValues.makes.includes(make)) {
            if (!filteredData.makes.includes(make)) {
                filteredData.makes.push(make);
            }
        }
    });

    allYears.forEach((year) => {
        if (allYearValues.years.includes(year)) {
            if (!filteredData.years.includes(year)) {
                filteredData.years.push(year);
            }
        }
    });

    allModels.forEach((model) => {
        if (allModelValues.models.includes(model)) {
            if (!filteredData.models.includes(model)) {
                filteredData.models.push(model);
            }
        }
    });

    allComponents.forEach((component) => {
        if (allComponentValues.components.includes(component)) {
            if (!filteredData.components.includes(component)) {
                filteredData.components.push(component);
            }
        }
    });

    return filteredData;
}

export function filterDataBySelections(
    totalItems: RefineCatalogsResponse[] | undefined,
    selectedValues: {
        make: string[];
        year: string[];
        model: string[];
        component: string[];
    }
) {
    if (!totalItems) {
        return { makes: [], years: [], models: [], components: [] };
    }

    const {
        make: selectedMakes,
        year: selectedYears,
        model: selectedModels,
        component: selectedComponents,
    } = selectedValues;

    let makes = new Set<string>();
    let years = new Set<string>();
    let models = new Set<string>();
    let components = new Set<string>();

    const convertedData = convertToMultiSelectComponentData(totalItems);

    convertedData.vehicles.forEach((makeObj) => {
        if (selectedMakes.length > 0 && !selectedMakes.includes(makeObj.make))
            return;

        let validYears = new Set<string>();
        let validModels = new Set<string>();
        let validComponents = new Set<string>();

        makeObj.years.forEach((yearObj) => {
            if (
                selectedYears.length > 0 &&
                !selectedYears.includes(yearObj.year)
            )
                return;

            yearObj.models.forEach((modelObj) => {
                if (
                    selectedModels.length > 0 &&
                    !selectedModels.includes(modelObj.model)
                )
                    return;

                let validModel = filterComponents(
                    modelObj.components,
                    selectedComponents,
                    validComponents
                );

                if (validModel) {
                    validYears.add(yearObj.year);
                    validModels.add(modelObj.model);
                }
            });
        });

        if (validYears.size > 0) {
            makes.add(makeObj.make);
            years = new Set([...years, ...validYears]);
            models = new Set([...models, ...validModels]);
            components = new Set([...components, ...validComponents]);
        }
    });

    return {
        makes: Array.from(makes),
        years: Array.from(years),
        models: Array.from(models),
        components: Array.from(components),
    };
}

function filterComponents(
    componentsList: any[],
    selectedComponents: string | any[],
    validComponents: Set<string>
) {
    let validModel = false;
    componentsList.forEach((componentCode) => {
        if (
            selectedComponents.length > 0 &&
            !selectedComponents.includes(componentCode)
        )
            return;

        validModel = true;
        validComponents.add(componentCode);
    });
    return validModel;
}

function convertMake(make: RefineCatalogsResponse): {
    make: string;
    years: Array<any>;
} {
    return {
        make: make.label,
        years: (make.items || []).map((year) => convertYear(year)),
    };
}

function convertYear(year: any): { year: string; models: Array<any> } {
    return {
        year: year.label,
        models: (year.items || []).map((model: any) => convertModel(model)),
    };
}

function convertModel(model: any): { model: string; components: string[] } {
    return {
        model: model.label,
        components: (model.items || []).map(
            (component: { label: any }) => component.label
        ),
    };
}
