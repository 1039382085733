import { useEffect } from 'react';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';

interface SliderDataResponse {
    minPrice: number | null;
    maxPrice: number | null;
    onSaleProducts: boolean;
    newProducts: boolean;
}

export const useSliderItemsData = (
    updatedFilterItems: IItemListResponse | undefined,
    isPriceRangeSelected: boolean,
    totalSearchItems: IItemListResponse | undefined,
    setPreviousMaxPrice: any,
    setPreviousMinPrice: any,
    previousMaxPrice: any,
    previousMinPrice: any
): SliderDataResponse => {
    let onSaleProducts = false;
    let newProducts = false;

    updatedFilterItems?.newProductPromo?.forEach((promo) => {
        if (promo.key === 'On_Sale') {
            onSaleProducts = true;
        } else if (promo.key === 'New_Products') {
            newProducts = true;
        }
    });

    useEffect(() => {
        if (!isPriceRangeSelected) {
            setPreviousMinPrice(updatedFilterItems?.minPrice ?? null);
            setPreviousMaxPrice(updatedFilterItems?.maxPrice ?? null);
        }
    }, [updatedFilterItems, isPriceRangeSelected, totalSearchItems]);
    const minPrice = previousMinPrice;
    const maxPrice = previousMaxPrice;
    let formattedMinPrice = null;
    if (minPrice != null) {
        formattedMinPrice = Number.isInteger(minPrice)
            ? minPrice.toFixed(0)
            : minPrice.toFixed(2);
    }

    let formattedMaxPrice = null;
    if (maxPrice != null) {
        formattedMaxPrice = Number.isInteger(maxPrice)
            ? maxPrice.toFixed(0)
            : maxPrice.toFixed(2);
    }

    return {
        minPrice: formattedMinPrice,
        maxPrice: formattedMaxPrice,
        newProducts,
        onSaleProducts,
    };
};
