import App from "srs.sharedcomponents/lib/esm/App";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  ApplicationInsights,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";
import { env } from "srs.sharedcomponents/lib/esm/api/env";
import { store } from "srs.sharedcomponents/lib/esm/redux/store";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";
import { setFooterLinks } from "srs.sharedcomponents/lib/esm/redux/slices/footerLinksSlice";
import { useDispatch } from "react-redux";
import { uuidv4 } from "srs.sharedcomponents/lib/esm/utils/helpers";
import axios, { AxiosResponse } from "axios";
import { i18nNamespaces as namespace } from "srs.sharedcomponents/lib/esm/i18n/i18n";
import coreSrsPrimaryOverride from "./i18n/coreSrsPrimaryOverride.json";
import { useTranslation } from "react-i18next";
import packageJson from "../package.json";

interface IAppWrapper {
  appInsights: ApplicationInsights;
}

const AppWrapper = ({ appInsights }: IAppWrapper) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) => {
    Object.entries(entity).forEach(([key, value]) => {
      if (typeof value === "string") {
        i18n.addResource(locale, namespace.coreSrsPrimary, key, value);
      } else {
        console.error(
          `Value for key '${key}' in locale '${locale}' is not a string:`,
          value
        );
      }
    });
  });

  const welcome = useAppSelector((state) => state.welcome);
  const footerLinks = useAppSelector((state) => state.footerLinks.links);


  axios.interceptors.request.use(
    (config) => {
      if (config.headers) {
        config.headers['Content-Type'] = 'application/json'
        config.headers["X-Correlation-Id"] = uuidv4();
      }
      try {
        if (store.getState().user.user?.token) {
          const token: string | undefined = store.getState().user.user?.token
          if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
        } else if (store.getState().welcome.welcome?.anonymousId) {
          const token: string | undefined = store?.getState().welcome.welcome?.anonymousId
          if (token && config.headers) config.headers.Authorization = `${token}`
        }

        if (config.headers) {
          appInsights.trackEvent({
            name: "API Request new-site",
            properties: {
              ApplicationName: "new-site",
              RequestPath: config.url ?? "",
              Method: config.method ?? "",
              CorrelationId: config.headers ? config.headers["X-Correlation-Id"] : "",
            },
          });
        }
      } catch (e: any) { }
      return config
    },
    (error) => {
      return Promise.reject(new Error(error.message))
    },
  )



  useEffect(() => {
    const myResponseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const { config, status, statusText } = response;
        const { method, url, headers } = config;

        if (config) {
          appInsights.trackEvent({
            name: "API Response new-site",
            properties: {
              ApplicationName: "new-site",
              RequestPath: url ?? "",
              Method: method ?? "",
              CorrelationId: headers ? headers["X-Correlation-Id"] : "",
              StatusCode: status,
              StatusCodeText: statusText,
            },
          });
        }

        return response;
      },
      (error) => {
        // You can also log the error details in case of request failure
        appInsights.trackEvent({
          name: "API Error",
          properties: {
            message: error.message,
            // Additional properties as needed
          },
        });

        return Promise.reject(new Error(error));
      }
    );

    return () => {
      axios.interceptors.response.eject(myResponseInterceptor);
    };
  });

  useEffect(() => {
    const pageInfo: IPageViewTelemetry = {
      name: location?.pathname,
      uri: window?.location.href,
      isLoggedIn: !!store.getState().user?.user?.token,
    };
    if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
      appInsights.trackPageView(pageInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    const newFooterLinks = footerLinks.filter(
      (link) => link.title !== "Footer_HowToOrder"
    );
    const newLinks = [
      ...newFooterLinks,
      {
        id: "howToOrder",
        route: `${welcome.welcome?.cmsBaseUrl}/UserManagedItems/Documents/Global/Footer/en-US/How%20To%20Order.htm`,
        title: "Footer_HowToOrder",
        target: "_blank",
      },
    ];

    dispatch(setFooterLinks(newLinks));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcome.welcome?.cmsBaseUrl]);

  useEffect(() => {
    if (window) {
      window.showVersion = () => {
        alert(
          `Sharedcomponent Version: ${packageJson?.dependencies["srs.sharedcomponents"]}`
        );
      };
    }
  }, []);

  return <App />;
};

export default AppWrapper;
